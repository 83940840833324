



















































































































































































































































































































































































































































































































h3 {
  line-height: 20px;
  border-left: 3px solid #5c6be8;
  padding-left: 4px;
  margin-bottom: 20px;
}
.flexcc {
  margin: 20px 0;
}
.el-input-number {
  width: 200px;
}
.el-table {
  margin-top: 20px;
  /deep/ .el-table__empty-block {
    min-height: 400px;
  }
}
